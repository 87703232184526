.mycluster {
    z-index: -10000 !important;
}

.leaflet-marker-icon {
    z-index: -10000 !important;
}

{
"image"
:
[
"https://fin.co.id/wp-content/uploads/2019/08/DKI-Jakarta.jpg"
]
,
"ranking_file"
:
"Jakarta.json"
}
.senderCluster {
    font-weight: 700;
    color: blue;
    font-size: 12px;
    position: absolute;
    top: 45%;
    left: 34%;
    transform: translate(-34%, -45%);
}

.senderCusterImage {
    width: 50px;
    height: 45px;
    position: relative
    /*margin-left: -22px; margin-top: -13px;*/
}

.receiverCluster {
    font-weight: 700;
    color: #68934d;
    font-size: 12px;
    top: 46%;
    left: 34%;
    transform: translate(-34%, -46%);
    position: absolute;
}

.receiverClusterImage {
    width: 50px;
    height: 45px;
    position: relative
    /*margin-left: -22px; margin-top: -13px;*/
}
