/*.App {*/
/*  text-align: center;*/
/*}*/

/*.App-logo {*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/
/*.yearInActive{*/
/*  background: #FFFFFF;*/
/*}*/


/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/
.MuiButton-containedSecondary {
    color: #554949 !important;
    background-color: #ffffff !important;;
}

.cardInfoDonation {
    position: 'absolute';
    top: '50%';
    z-index: 100000;
    right: '74px';
    padding: '10px 10px 0px 10px';
    margin: '10px 10px 20px 20px';
    width: '35%';
    background: '#ffffffe3';
    box-shadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)';
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)';
}

.cardInfoDonation:after {
    content: " ";
    position: absolute;
    right: -15px;
    top: 15px;
    border-top: 15px solid transparent;
    border-right: none;
    border-left: 15px solid rgb(255, 255, 255);
    border-bottom: 15px solid transparent;
    animation-name: fadeOut;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    will-change: opacity;
}

.cardInfoDonation:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22)
}

.cardInfoDonationMini::after {
    content: " ";
    position: absolute;
    left: -15px;
    top: 15px;
    border-top: 15px solid transparent;
    border-right: 15px solid rgb(255, 255, 255);
    border-left: none;
    border-bottom: 15px solid transparent;
    animation-name: fadeOut;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    will-change: opacity;
}


.cardInfoLegendMini::after {
    content: " ";
    position: absolute;
    left: -15px;
    top: 10px;
    border-top: 15px solid transparent;
    border-right: 15px solid rgb(255, 255, 255);
    border-left: none;
    border-bottom: 15px solid transparent;
    animation-name: fadeOut;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    will-change: opacity;
}

.cardInfoLegend::after {
    content: " ";
    position: absolute;
    right: -15px;
    top: 10px;
    border-top: 15px solid transparent;
    border-right: none;
    border-left: 15px solid rgb(255, 255, 255);
    border-bottom: 15px solid transparent;
    animation-name: fadeOut;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    will-change: opacity;
}

.btnInfoDonation {
    right: '2%' !important;
    position: 'absolute' !important;
    top: '55%' !important;
    z-index: 10000 !important;
    height: '55px';
    width: '30px';
    border-radius: '50%' !important;
    background: '#ffffffeb' !important
}

.btnInfoDonation svg {
    color: rgb(101 144 73) !important
}

.btnLegendActive svg {
    color: rgb(101 144 73) !important
}


.react-autosuggest__suggestions-container {
    z-index: 99999999;
    max-height: 75vh;
    overflow: scroll;
}

.swal-text {
    text-align: center;
}
